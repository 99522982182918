const parentTemplate  =
    `<!DOCTYPE html>
    <html>
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Your Notification Title</title>
    </head>
    <body style="margin: 0; padding: 0; font-family: Arial, sans-serif; background-color:#F6F8FA;">
        <table role="presentation" align="center" border="0" cellpadding="0" cellspacing="0" width="700">
        <tr style="background-color:#F6F8FA;">
            <td  style="padding: 20px; text-align: center;">
                <p style="margin: 10px 10px 10px 0; color: #333333; display: inline-block; vertical-align: middle; font-weight:550;">{{Your Header Comes Here}}</p>
            </td>
        </tr>
        <tr>
            <td colspan="2" bgcolor="#ffffff" style="margin: 20px; padding: 5px; border: 1px solid #dddddd; border-radius: 5px;">
                <p style="margin: 10px 0; padding: 15px; color: #777777;">
                    <!-- User's dynamic content from CKEditor goes here -->
                    {{content_data}}
                </p>
            </td>
        </tr>
        <tr style="background-color:#F6F8FA;">
            <td colspan="2" style="padding: 20px; text-align: center;">
                <p style="margin: 0; color: #777777;">
                {{Your Footer Comes Here}}
                </p>
            </td>
        </tr>
    </table>
    </body>
    </html>`

const headerFooterTemplate = 
`<!DOCTYPE html>
<html>
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Your Notification Title</title>
</head>
<body style="margin: 0; padding: 0; font-family: Arial, sans-serif; background-color:#F6F8FA;">
    <table role="presentation" align="center" border="0" cellpadding="0" cellspacing="0" width="700">
    <tr style="background-color:#F6F8FA;  padding: 10px;"; >
        <td style="margin-top : 10px;">
           {{header_content}} 
        </td>
    </tr>
    <tr>
        <td colspan="2" bgcolor="#ffffff" style="margin: 20px; padding: 5px; border: 1px solid #dddddd; border-radius: 5px;">
            <p style="margin: 10px 0; padding: 15px; color: #777777;">
               {{Your content will be displayed here}}
            </p>
        </td>
    </tr>
    <tr style="background-color:#F6F8FA;">
        <td colspan="2" style="padding: 10px;">
           {{footer_content}}
        </td>
    </tr>
</table>
</body>
</html>`

export const configs = {
    parentTemplate : parentTemplate,
    headerFooterTemplate : headerFooterTemplate
}